#footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #1acc8d;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #149f6e;
  }

  .footer-newsletter {padding: 50px 0;background: #e5f1f8;}
.footer-newsletter h4 {font-size: 24px;margin: 0 0 10px 0;padding: 0;line-height: 1;font-weight: 600;color: #008bd0;}
.footer-newsletter form {margin-top: 20px;background: #fff;padding: 6px 10px;position: relative;border-radius: 4px;border: 1px solid #e1ecff;}
.footer-newsletter form input[type=email] {border: 0;padding: 2px;width: calc(100% - 140px);}
.footer-newsletter form input[type=submit] {position: absolute;top: 0;right: 0;bottom: 0;border: 0;background: none;font-size: 16px;font-weight: 500;padding: 0 30px;margin: 3px;background: #008bd0;color: #fff;transition: 0.3s;border-radius: 4px;}


.privacy h5{color: #008bd0;}
.privacy a:hover{text-decoration: underline;}
.privacy ul li{color:#424242;}

