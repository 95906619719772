.container.vision .card {
  height: 220px;
  padding-top: 20px;
}
.cart-box {
  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.cart-box img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
}
.box-image{
  border-radius: 0px;
}
.cart-box h5 {
  font-size: 18px;
  line-height: 28px;
}
.cart-box p {
  padding-left: 15px;
}

.cart-box h5 {
  padding-left: 15px;
}
#hero{
   background:url("../../assets/img/inner/inner-bg.jpg");
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
   color: #fff !important;
}
section#hero h1 {
  color: #fff;
}


#abouthero{
  background-image: url("../../assets/img/about.jpeg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

}
.about{
  background-image: url("../../assets/img/new\ image_.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
 
}

.vision{

       
  width: 100%;
  background-color: #e5f1f8;
  

}

.vision h4{
color: black;
}

.socialIcon{
  width: 20px;
  margin: 10px;
}

.socialIcon:hover{
  transform: scale(1.18);
  transition: 0.6s;
}


.bg{
  background-color: #e5f1f8;
}

.paratext{
  color: white;
}

.icon__about{
  width: 50px;
  background-color:#008ad0 ;
  border-radius: 50%;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.icon__about2{
  width: 50px;
  background-color:#eb3e41 ;
  border-radius: 50%;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
}





.socialIcon{
  width: 20px;
  margin: 10px;
}

.socialIcon:hover{
  transform: scale(1.18);
  transition: 0.6s;
}

.inner__col{
  display: flex;
  margin-top: 20px;
 
      flex-direction: column;
      justify-content: center;
      text-align: center;
     
      

}



.desc__box{
 
  box-shadow: 3px 3px 3px 3px rgba(17, 7, 7, 0.05);
  padding: 50px;
  border-radius: 30px;
}



.responsive-cell-block {
  min-height: 75px;
}

.text-blk {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  line-height: 25px;
}

.responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  justify-content: space-evenly;
}

.team-head-text {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
}

.teamcard{
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-right: 25px;
  padding-bottom: 30px;
  padding-left: 25px;
}

.team-head-text {
  line-height: 50px;
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
  margin-left: 0px;
}




.card-container {
  width: 240px;
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 25px;
  margin-left: 10px;
}

.name {
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 5px;
  margin-left: 0px;
  font-size: 18px;
  font-weight: 800;
}

.position {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

.feature-text {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
  color: rgb(122, 122, 122);
  line-height: 30px;
}

.social-icons {
  width: 70px;
  display: flex;
  justify-content: space-between;
}

.team-image-wrapper {
  clip-path: circle(50% at 50% 50%);
  width: 130px;
  height: 130px;
}

.team-member-image {
  max-width: 100%;
}

.teamcard{
  background-color:#e5f1f8 ;
}

.teamcard:hover{
 transform: scale(1.08);
 transition: 0.6s;
}

@media (max-width: 500px) {
  .card-container {
    width: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 25px;
    margin-left: 0px;
  }

  .inner__col{
      flex-direction: column;
      justify-content: center;
      text-align: center;
  }

  .ourmission{
      text-align: center;
  }
}
@media only screen and (max-width:767px){
  .container.vision .card{
    height: 230px;
  }
  .container.vision .card p br {
    display: none;
}
}

@media only screen and (max-width:768px){
 
  .inner__col{
      flex-direction: column;
      justify-content: center;
      text-align: center;
  }
  
  .ourmission{
      text-align: center;
  }


  

}

@media only screen and (max-width:991px){
 
  .index{
      background-image: url("../../assets/img/about\ B1.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: 330px;
      width: 90%;
      margin-left: 5%;
      border-radius: 50px;
      margin-top: 20px;
  }

  .vision{

      background-image: url("../../assets/img/abouttt.jpg");
      background-repeat: no-repeat;
      background-size:cover ;
      background-position: center center;
      width: 100%;
    

}

.vision p{
  color: black;
}

}



  @media (min-width:1400px){
    .about {padding: 80px 0 0 0;}
  
    .featured .member img{width:100%;}
    .our-mission .pic img {width: 15%;}
  }




  .team {background: #e5f1f8;}
.team .member {text-align: center;margin-bottom: 80px;position: relative;}
.team .member .pic {border-radius: 4px;overflow: hidden;}
.team .member img {transition: all ease-in-out 0.4s;}
.team .member:hover img {transform: scale(1.1);}
.team .member .member-info {position: absolute;bottom: -80px;left: 0px;right: 0px;background: rgba(255, 255, 255, 0.9);padding: 15px 0;border-radius: 0 0 4px 4px;box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);}
.team .member h4 {font-weight: 700;margin-bottom: 10px;font-size: 16px;color: #008bd0;position: relative;padding-bottom: 10px;}
.team .member h4::after {content: "";position: absolute;display: block;width: 50px;height: 1px;background: #008bd0;bottom: 0;left: calc(50% - 25px);}
.team .member span {font-style: italic;display: block;font-size: 13px;color: #424242;}




.inner-area img{position: relative; border-bottom:10px solid #008bd0; border-right:10px solid #008bd0;}


.how-we-do{background-color: #e5f1f8;}
.steps-reach-w3l {position: relative;text-align: center;}
.steps-reach-w3l span {line-height: 0;}
.step1-bg{background: #e73c3f;animation: scale 1.3s alternate infinite ease-in;}
.step2-bg{background: #164a64;animation: scale 1.5s alternate infinite ease-in;}
.step3-bg{background: #400a0b;animation: scale 1.8s alternate infinite ease-in;}
.step4-bg{background: #008bd0;animation: scale 2.1s alternate infinite ease-in;}
.steps-reach-w3l span span {color: #fff;font-size: 28px;width: 100px;height: 100px;border: 2px solid #fff;line-height: 3.2;border-radius: 50%;display: inline-block;cursor: pointer;}
.steps-reach-w3l p {color: #404145;font-size: 14px;font-weight: 600;line-height: 18px;cursor: pointer;}
.style-agile-border {position: absolute;top: 43px;right: -49px;}
@keyframes scale {
  0% {
      transform: scale(.8);
  }
  100% {
      transform: scale(1.1);
  }
}
.demo-btn{border-radius:0; font-size:20px; font-weight:600; box-shadow:2px 4px 4px rgba(101,101,101,0.5); width:50%;}
.demo-btn:hover{ box-shadow:0px 8px 10px rgba(101,101,101,0.5);}


#hero {width: 100%;position: relative;padding: 50px 0 80px 0;}
#hero h1 {margin: 0 0 20px 0;font-size: 39px;font-weight: 600;line-height: 45px;color: rgba(0, 0, 0, 1);}
#hero h1 span {color: #fff;border-bottom: 4px solid #1acc8d;}
#hero h2 {color: rgba(255, 255, 255, 0.8);margin-bottom: 40px;font-size: 24px;}
.hero-img img{position: relative; left:30px; top:60px;}
#hero .animated {animation: up-down 2s ease-in-out infinite alternate-reverse both;}

@media (min-width: 1024px) {
/* #hero {background-attachment: fixed;} */
}

@media (max-width: 991px) {
#hero {padding-top: 80px;}
#hero .animated {-webkit-animation: none;animation: none;}
#hero .hero-img {text-align: center;}
#hero .hero-img img {max-width: 100%;}
#hero h1 {font-size: 28px;line-height: 32px;margin-bottom: 10px;}
#hero h2 {font-size: 18px;line-height: 24px;margin-bottom: 30px;}
}

@media (max-width: 575px) {
#hero .hero-img img {width: 100%;}
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}


.c1{color:#008bd0;}


/* 
Client component Css */



.testimonials {
    padding: 80px 0;
    background:#e5f1f8;
   
  }
  
  .section-header {
    margin-bottom: 40px;
  }
  .testimonials-carousel,
   .testimonials-slider {
    overflow: hidden;
  }
  
  .testimonial-item {
    text-align: center;
    color: #000;
    border: 2px solid whitesmoke;
    padding: 25px;
  }
  
  .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
  }
  
   .testimonial-item h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0 5px 0;
    color: #424242;
  }
  
  .testimonial-item h4 {
    font-size: 14px;
    color: #000;
    margin: 0 0 15px 0;
  }
  
 
  
  
  
   .testimonial-item p {
    margin: 0 auto 40px auto;
    color: #424242;
    font-size:20px;
    max-width: 600px;
  }

  .swiper-slide-prev{
filter: blur(4px);
  }

  .swiper-slide-next{
    filter: blur(4px);
      }

      .swiper-slide-active{
        box-shadow: 2px 2px 12px 2px rgb(163, 161, 161) ;
        background-color: whitesmoke;
        border-bottom: 2px solid rgb(99, 98, 98) ;
          }
  
.whowearediv{
  padding: 15px;
  max-width: 300px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 1px 1px 1px 1px rgb(131, 130, 130);
  height: 180px;
}

.whowearediv:hover{
  transform: scale(1.05);
  transition: width 2s, height 4s;
}
  
.mySwiper{
  margin-top: 100px;
}
  
  
  @media (min-width: 1024px) {
    .testimonials {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 992px) {
    .testimonial-item p {
      max-width: 70%;
    }
  }




