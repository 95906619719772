*{
    font-family: 'Roboto', sans-serif;
}

h2{
    font-size: 30px;
}

h4{
    font-size: 20px;
}

p{

        color: #424242;
        font-size: 16px;
 
}
button.btn{
  background-color: #0089cf !important;
  color: white;
}

a{
    text-decoration: none;
}
  

@media only screen and (max-width:991px){

    h1{
        font-size: 26px;
        font-weight: 700;
  }
  h2{
      font-size: 25px;
      font-weight: 600;
  }
      
  h3{
      font-size: 23px;
      font-weight: 600;
  }
  h4{
      font-size: 21px;
      font-weight: 500;
  }
  
  h5{
      font-size: 18px;
      font-weight: 500;
  }
  
  h6{
      font-size: 16px;
      font-weight: 500;
  }
  
  p{
      font-size: 15px;
      font-weight: 400;
      color: #424242;
  }
  
  }


@media only screen and (min-width:568px) and (max-width:768px){
h1{
      font-size: 25px;
      font-weight: 600;
}
h2{
    font-size: 23px;
    font-weight: 600;
}
    
h3{
    font-size: 21px;
    font-weight: 600;
}
h4{
    font-size: 20px;
    font-weight: 500;
}

h5{
    font-size: 19px;
    font-weight: 500;
}

h6{
    font-size: 18px;
    font-weight: 500;
}

p{
    font-size: 15px;
    font-weight: 400;
}

}

@media only screen and (max-width:568px){

    h1{
        font-size: 22px;
        font-weight: 600;
  }
  h2{
      font-size: 20px;
      font-weight: 600;
  }
      
  h3{
      font-size: 19px;
      font-weight: 600;
  }
  h4{
      font-size: 18px;
      font-weight: 500;
  }
  
  h5{
      font-size: 17px;
      font-weight: 500;
  }
  
  h6{
      font-size: 16px;
      font-weight: 500;
  }
  
  p{
    font-size: 14px;
   
  }
}




.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .myBtn{
    padding: 20px;
  }