.nav__image{
    max-width: 200px;
}

.nav-item{
    font-weight: 500;
    color: black;
    font-size: 15px;
}

.navbarphidden{
    display: none;
}

.hidden{
   visibility: hidden;
}
.navbar-nav{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
}

.navbarp{
color: white;
background-color: #008bd0;
box-shadow: 0px 0px 1px 0px #008bd0;
border-radius: 5px;
}
.nav_span{
    color: white;
    font-weight: 400;
}


@media only screen and (max-width:991px){
.nav__resp{
    display: none;
}

.hidden{
   visibility: visible;
}

#form {

display: none !important;
}
}

@media only screen and (max-width:991px){
    div#navbarNavDropdown {
        position: absolute;
        background-color: #fff;
        width: 100%;
        top: 60px;
        left: 0px;
    }
}

/* .collapse:not(.show) {
    display: block;
  }
  .collapse:not(.show) {
    display: block;
  } */